.react-datetimerange-picker {
  margin-top: 0;
  height: 50px;
  background: black;
  display: inline-flex;
  position: relative;
}
.react-datetimerange-picker,
.react-datetimerange-picker *,
.react-datetimerange-picker *:before,
.react-datetimerange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-datetimerange-picker--disabled {
  background-color: #000;
  color: #6d6d6d;
}
.react-datetimerange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: 0 solid gray;
  padding-left: 8px;
  padding-right: 8px;
  /* min-height: "100px"; */
}
.react-datetimerange-picker__inputGroup {
  min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
  height: 100%;
  flex-grow: 1;
  padding: 0 20px;
}

.react-datetimerange-picker__inputGroup__divider {
  padding: 1px 0;
}
.react-datetimerange-picker__inputGroup__input {
  min-width: 1em;
  height: calc(100% - 2px);
  position: relative;
  padding: 3px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}

/* Phone */
@media only screen and (max-width: 414px) {
  .react-datetimerange-picker__inputGroup {
    min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
    height: 100%;
    flex-grow: 1;
    padding: 0 2px;
  }

  .react-datetimerange-picker__inputGroup__input {
    min-width: 1px;
    height: calc(100% - 2px);
    position: relative;
    padding: 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
}
.react-datetimerange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-datetimerange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datetimerange-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-datetimerange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-datetimerange-picker__inputGroup__amPm {
  font: inherit;
  -moz-appearance: menulist;
}
.react-datetimerange-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
  display: none;
}

@media only screen and (min-width: 414px) {
  .react-datetimerange-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
    display: none;
  }
}

.react-datetimerange-picker__button:enabled {
  cursor: pointer;
  display: none;
}
.react-datetimerange-picker__button:enabled:hover
  .react-datetimerange-picker__button__icon,
.react-datetimerange-picker__button:enabled:focus
  .react-datetimerange-picker__button__icon {
  stroke: #555;
}
.react-datetimerange-picker__button:disabled
  .react-datetimerange-picker__button__icon {
  stroke: #6d6d6d;
}
.react-datetimerange-picker__button svg {
  display: inherit;
}
.react-datetimerange-picker__calendar,
.react-datetimerange-picker__clock {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-datetimerange-picker__calendar--closed,
.react-datetimerange-picker__clock--closed {
  display: none;
}
.react-datetimerange-picker__calendar {
  width: 350px;
  max-width: 100vw;
}
.react-datetimerange-picker__calendar .react-calendar {
  border-width: thin;
}
.react-datetimerange-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: #171717;
  border: thin solid #7ae7ff;
}
/* 
#custom-time-picker {
  border: 1px yellow solid !important;
} */

/* react-calendar */
#custom-time-picker
  .react-datetimerange-picker__calendar.react-datetimerange-picker__calendar--open,
#custom-time-picker
  .react-datetimerange-picker__clock.react-datetimerange-picker__clock--open {
  background-color: #171717;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background-color: #171717;
  border: 1px solid #171717;
  shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* // react-clock */
.react-clock {
  display: block;
  position: relative;
}
.react-clock,
.react-clock *,
.react-clock *:before,
.react-clock *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-clock__face {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #7ae7ff;
  border-radius: 50%;
}
.react-clock__hand {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}
.react-clock__hand__body {
  position: absolute;
  background-color: #7ae7ff;
  transform: translateX(-50%);
}
.react-clock__mark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}
.react-clock__mark__body {
  position: absolute;
  background-color: #7ae7ff;
  transform: translateX(-50%);
}
.react-clock__mark__number {
  position: absolute;
  left: -40px;
  width: 80px;
  text-align: center;
}
.react-clock__second-hand__body {
  background-color: red;
}

.react-datetimerange-picker__clear-button .react-datetimerange-picker__button {
  display: "none";
}
